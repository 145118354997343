import React, { useState,useEffect } from 'react';
import DateTime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import axios from 'axios';
import moment from 'moment';
import './App.css'; // Import the CSS file
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { v4 as uuidv4 } from 'uuid';

function App() {
  const [date, setDate] = useState(null);
  const [hours, setHours] = useState('00');     
  const [minutes, setMinutes] = useState('00');
  const [seconds, setSeconds] = useState('00');
  const [milliseconds, setMilliseconds] = useState('000');
  const [TradingSymbol, setTradingSymbol] = useState('');
  const [schedules, setSchedules] = useState([]);
  const [currentDateTime, setCurrentDateTime] = useState('');
  const [price,setPrice]=useState('0.00');
  const [quantity,setQuantity]=useState(0)
  const userId='1416341'
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentDateTime(moment().format('YYYY-MM-DD HH:mm:ss.SSS'));
    }, 1); // Update every millisecond

    // Clear the interval when the component is unmounted
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    // Fetch schedules from the server
    const fetchSchedules = async () => {
      try {
        const response = await axios.get('https://app.msgsender.in/api/scheduledOrders');
        setSchedules(response.data);
      } catch (err) {
        console.error(err);
        toast.error('Failed to show scheduled orders');
      }
    };

    fetchSchedules();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    // Validate time format
    const timePattern = /^([01]\d|2[0-3]):([0-5]\d):([0-5]\d).([0-9]{1,3})$/;
    const timeString = `${hours}:${minutes}:${seconds}.${milliseconds}`;
    if (!timePattern.test(timeString)) {
      toast.error('Time must be in the format HH:MM:SS.SSS.');
      return;
    }
  
    // Combine date and time
    const formattedDateTime = `${moment(date).format('YYYY-MM-DD')} ${timeString}`;
  
    // Check if the scheduled dateTime is in the future
    const now = moment();
    const scheduleDateTime = moment(formattedDateTime, 'YYYY-MM-DD HH:mm:ss.SSS');
    const currentDateTime = moment(now).format('YYYY-MM-DD HH:mm:ss.SSS')
    if (scheduleDateTime.isBefore(now)) {
      toast.error('Scheduled date and time must be in the future.');
      return;
    }
    const uniqueId = uuidv4();
    try {
      // Send the data to the API
      await axios.post('https://app.msgsender.in/api/schedule', {
        id: uniqueId,
        stockName: TradingSymbol,
        qty:quantity ,
        price:price, 
        createAt:currentDateTime, 
        updatedAt:currentDateTime, 
        orderStatus:'scheduled',
        scheduleAt:formattedDateTime,
        userId:userId 
      });
      
      // Update local state if needed
      setSchedules([...schedules, { id: uniqueId,
        stock_name: TradingSymbol ,
        qty:quantity ,
        price:price, 
        create_at:currentDateTime, 
        updated_at:currentDateTime, 
        order_status:'scheduled',
        schedule_at:formattedDateTime,
        userId:userId  }]);
      
      toast.success('Order scheduled successfully');
      
      // Clear form
      setDate(null);
      setHours('00');
      setMinutes('00');
      setSeconds('00');
      setMilliseconds('000');
      setTradingSymbol('');
      setQuantity(0);
      setPrice('0.00')
    } catch (err) {
      console.error(err);
      toast.error('Failed to schedule the order');
    }
  };
  return (
    <div className="App">
      <div className="current-date-time">{currentDateTime}</div>
      <h1>Scheduler Tool</h1>
      <form onSubmit={handleSubmit} className="stock-form">
      <fieldset className="form-group">
        <legend>Date Selection</legend>
        <div className="form-control">
          <label>Date:</label>
          <DateTime
            value={date}
            onChange={(date) => setDate(date)}
            dateFormat="YYYY-MM-DD"
            timeFormat={false}
            closeOnSelect={true}
            inputProps={{ placeholder: "Select date" }}
            required
          />
        </div>
      </fieldset>

      <fieldset className="form-group">
        <legend>Time Selection (HH:MM:SS.SSS)</legend>
        <div className="time-picker form-control">
          <select value={hours} onChange={(e) => setHours(e.target.value)}>
            {[...Array(24).keys()].map(h => (
              <option key={h} value={h.toString().padStart(2, '0')}>
                {h.toString().padStart(2, '0')}
              </option>
            ))}
          </select>
          <span>:</span>
          <select value={minutes} onChange={(e) => setMinutes(e.target.value)}>
            {[...Array(60).keys()].map(m => (
              <option key={m} value={m.toString().padStart(2, '0')}>
                {m.toString().padStart(2, '0')}
              </option>
            ))}
          </select>
          <span>:</span>
          <select value={seconds} onChange={(e) => setSeconds(e.target.value)}>
            {[...Array(60).keys()].map(s => (
              <option key={s} value={s.toString().padStart(2, '0')}>
                {s.toString().padStart(2, '0')}
              </option>
            ))}
          </select>
          <span>:</span>
          <select value={milliseconds} onChange={(e) => setMilliseconds(e.target.value)}>
            {[...Array(10).keys()].map(i => i * 100).map(ms => (
              <option key={ms} value={ms}>
                {ms.toString().padStart(3, '0')}
              </option>
            ))}
          </select>
        </div>
      </fieldset>

      <fieldset className="form-group stock-selection">
        <legend>Select Trading Symbol</legend>
        <div className="form-control">
          <label>
            <input
              type="radio"
              name="stock"
              value="HINDMOTORS-EQ"
              checked={TradingSymbol === 'HINDMOTORS-EQ'}
              onChange={(e) => setTradingSymbol(e.target.value)}
            />
            HINDMOTORS
          </label>
          <label>
            <input
              type="radio"
              name="TradingSymbol"
              value="SUZLON-EQ"
              checked={TradingSymbol === 'SUZLON-EQ'}
              onChange={(e) => setTradingSymbol(e.target.value)}
            />
            SUZLON
          </label>
        </div>
      </fieldset>

      <fieldset className="form-group quantity-price-container">
        <legend>Quantity and Price</legend>
        <div className="quantity-price form-control">
          <div className="quantity">
            <label>
              Quantity:
              <input
                type="number"
                value={quantity}
                onChange={(e) => setQuantity(e.target.value)}
                min="0"
                placeholder="Enter quantity"
              />
            </label>
          </div>

          <div className="price">
            <label>
              Price:
              <div className="price-input-container">
                <span className="currency-symbol">₹</span>
                <input
                  type="number"
                  value={price}
                  onChange={(e) => setPrice(e.target.value)}
                  min="0"
                  step="0.01"
                  placeholder="Enter price"
                />
              </div>
            </label>
          </div>
        </div>
      </fieldset>

      <button type="submit">Schedule Order</button>
    </form>

      <div className="schedule-list">
        <h2>Scheduled Orders</h2>
        {schedules.length > 0 ? (
        <ul>
          {schedules
            .sort((a, b) => new Date(b.schedule_at) - new Date(a.schedule_at)) // Sort by scheduleAt in descending order
            .map((schedule, index) => (
              <li key={index} className="schedule-item">
                <span className="serial-no">{index + 1}.</span>
                <div className="schedule-content">
                  <span className="schedule-tag">{schedule.stock_name}</span>
                  <span className="schedule-time">Qty:{schedule.qty}</span>
                  <span className="schedule-time">₹{schedule.price}</span>
                  <span className="schedule-time">{ moment(schedule.schedule_at).format('YYYY-MM-DD HH:mm:ss.SSS')}</span>
                </div>
              </li>
            ))}
        </ul>
) : (
  <p>No Orders Scheduled</p>
)}

      </div>

      <ToastContainer />
    </div>
  );
}

export default App;

